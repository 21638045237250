import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1dafcf5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "nav-menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ReplenishIcon = _resolveComponent("ReplenishIcon")!
  const _component_ConclusionIcon = _resolveComponent("ConclusionIcon")!
  const _component_WalletsIcon = _resolveComponent("WalletsIcon")!
  const _component_HistoryIcon = _resolveComponent("HistoryIcon")!
  const _component_StructureIcon = _resolveComponent("StructureIcon")!
  const _component_SettingsIcon = _resolveComponent("SettingsIcon")!

  return (this.translateData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          class: "nav-menu__link",
          to: "/dashboard/home"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_HomeIcon),
            _createTextVNode(_toDisplayString(this.translateData.menu_dashboard), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "nav-menu__link",
          to: "/dashboard/replenishment"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ReplenishIcon),
            _createTextVNode(_toDisplayString(this.translateData.menu_deposit), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "nav-menu__link",
          to: "/dashboard/output"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ConclusionIcon),
            _createTextVNode(_toDisplayString(this.translateData.menu_withdrawal), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "nav-menu__link",
          to: "/dashboard/my-wallets"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_WalletsIcon),
            _createTextVNode(_toDisplayString(this.translateData.menu_wallets), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "nav-menu__link",
          to: "/dashboard/history"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_HistoryIcon),
            _createTextVNode(_toDisplayString(this.translateData.menu_history), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "nav-menu__link",
          to: "/dashboard/my-structure"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_StructureIcon),
            _createTextVNode(_toDisplayString(this.translateData.menu_my_structure), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "nav-menu__link",
          to: "/dashboard/settings"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SettingsIcon),
            _createTextVNode(_toDisplayString(this.translateData.settings), 1)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}