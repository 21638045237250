import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6549e052"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__burger" }
const _hoisted_3 = {
  key: 0,
  class: "header__right-group"
}
const _hoisted_4 = { class: "header__lang" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_WalletMenu = _resolveComponent("WalletMenu")!
  const _component_Lang = _resolveComponent("Lang")!
  const _component_NavMenuMob = _resolveComponent("NavMenuMob")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MenuIcon, {
        onClick: _ctx.openMobMenu,
        class: "header__burger-icon"
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createVNode(_component_Logo, { class: "header__logo" })
        ]),
        _: 1
      })
    ]),
    (this.translateData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_WalletMenu),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Lang)
          ]),
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLogout && _ctx.handleLogout(...args))),
            class: "header__exit"
          }, _toDisplayString(this.translateData.logout), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.mobileMenu)
      ? (_openBlock(), _createBlock(_component_NavMenuMob, {
          key: 1,
          handleClose: _ctx.closeMobMenu
        }, null, 8, ["handleClose"]))
      : _createCommentVNode("", true)
  ]))
}